import {
  CurrentUser,
  LoginResultSessionVM,
  LoginVM,
  Menu,
  RMTCreatedUser,
  UserVM,
  VwLoginReport,
  TwoFactorUser,
  Notification,
  PostNotification,
  NotificationResult,
  ExternalResponse,
  Role
} from '@/models'
import axios, { AxiosResponse } from 'axios'
const API_URL = 'Authentication'

export const login = async (
  model: LoginVM
): Promise<AxiosResponse<LoginResultSessionVM>> => {
  const response = await axios.post<LoginResultSessionVM>(API_URL, model)
  return response
}
export const loginWithGoogle = async (
  ExternalResponse: ExternalResponse
): Promise<AxiosResponse<LoginResultSessionVM>> => {
  const response = await axios.post<LoginResultSessionVM>(
    API_URL + '/GoogleSignIn',
    ExternalResponse
  )
  // console.log(googleToken)
  return response
}
export const isAuthenticated = (): boolean => {
  let isAuth = false
  if (localStorage.getItem('currentUser') != null) {
    isAuth = true
  } else {
    isAuth = false
  }
  return isAuth
}
export const getUsers = async (
  pageNumber = 1,
  pageSize = 10,
  searchQuery = ''
): Promise<AxiosResponse<RMTCreatedUser[]>> => {
  const response = await axios.get<RMTCreatedUser[]>(
    `Users?pageNumber=${pageNumber}&pageSize=${pageSize}&SearchQuery=${searchQuery}`
  )
  return response || null
}

export const getLoginReport = async (
  pageNumber = 1,
  pageSize = 10,
  searchQuery = ''
): Promise<AxiosResponse<VwLoginReport[]>> => {
  const response = await axios.get<VwLoginReport[]>(
    API_URL +
      `?pageNumber=${pageNumber}&pageSize=${pageSize}&SearchQuery=${searchQuery}`
  )
  return response || null
}

export const needsADoctor = (): boolean | null => {
  if (localStorage.getItem('needsADoctor') == null) return false
  return localStorage.getItem('needsADoctor') == 'true' ? true : false
}
export const getSelectedDoctor = (): string | null => {
  return localStorage.getItem('selectedDoctor')
}
export const getMenu = (): Menu[] => {
  const json = JSON.parse(JSON.stringify(localStorage.getItem('currentUser')))
  return JSON.parse(json).menus
}
export const getUserRole = (): string => {
  const json = JSON.parse(JSON.stringify(localStorage.getItem('currentUser')))
  return JSON.parse(json).role
}

export const getCurrentUser = (): CurrentUser => {
  const user =
    getUserRole() === Role.Doctor
      ? localStorage.getItem('currentUser')
      : localStorage.getItem('currentDoctor')

  const json = JSON.parse(JSON.stringify(user))
  return JSON.parse(json)
}
export const getCurrentDoctor = (): string => {
  const json = JSON.parse(JSON.stringify(localStorage.getItem('currentDoctor')))
  return JSON.parse(json)
}
export const _change2FA = async (
  model: TwoFactorUser
): Promise<TwoFactorUser[]> => {
  const { data } = await axios.put<TwoFactorUser[]>(`Users/Update2FA`, model)
  return data
}
export const deleteUser = async (userId: number): Promise<string> => {
  const { data } = await axios.delete('Users/DeleteUser', {
    params: { id: userId }
  })
  return data
}
export const resendPassword = async (model: UserVM): Promise<string> => {
  const { data } = await axios.post('Users/ResendPassword', model)
  return data
}
export const _toggleMaintenance = async (
  isUnderMaintenance: boolean
): Promise<boolean> => {
  // const obj = { isUnderMaintenance: true }
  const config = { headers: { 'Content-Type': 'application/json' } }
  await axios.post('Users/maintenance', isUnderMaintenance, config)
  return true
}
export const _validatePassword = async (password: string): Promise<boolean> => {
  const config = { headers: { 'Content-Type': 'application/json' } }
  return (
    await axios.post('Users/ValidatePassword', JSON.stringify(password), config)
  ).data
}
export const getNotifications = async (
  userId: string
): Promise<AxiosResponse<Notification[]>> => {
  const response = await axios.get<Notification[]>(
    `Notifications?userId=${userId}`
  )
  return response || null
}

export const sendNotification = async (
  model: PostNotification
): Promise<AxiosResponse<NotificationResult>> => {
  const { data } = await axios.post('Notifications', model)
  return data
}

export const markAsRead = async (notificationId: number): Promise<boolean> => {
  const { data } = await axios.put(
    `Notifications/UpdateNotification/${notificationId}`
  )
  return data
}
export default {
  getMenu,
  getUserRole
}

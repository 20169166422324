import {
  CalculatorResolve,
  Patient,
  PatientInfo,
  PatientList,
  PatientPostResult,
  PatientResolve,
  PatientVM,
  RecordDiagnostic,
  Role,
  UpdateBillingSemester,
  UpdatePatientStatus
} from '@/models'
import axios, { AxiosResponse } from 'axios'
import { getUserRole } from '@/components/admin/users/users.service'
const API_URL = 'Patients'

export const getPatientsAll = async (): Promise<AxiosResponse<Patient[]>> => {
  let doctorId: string | null = null
  if (getUserRole() == Role.Staff) {
    doctorId = localStorage.getItem('selectedDoctor')
  }
  // let url = `?pageNumber=${pageNumber}&pageSize=${pageSize}&InsuranceName=${insuranceName}&SearchQuery=${searchQuery}`
  let url = '/GetPatientListAll'
  if (doctorId) {
    url += `?doctorId=${doctorId}`
  }
  const response = await axios.get<Patient[]>(API_URL + url)
  return response || null
}
export const getPatients = async (
  pageNumber = 1,
  pageSize = 10,
  searchQuery = '',
  insuranceName = ''
): Promise<AxiosResponse<Patient[]>> => {
  let doctorId: string | null = null
  if (getUserRole() == Role.Staff) {
    doctorId = localStorage.getItem('selectedDoctor')
  }
  let url = `?pageNumber=${pageNumber}&pageSize=${pageSize}&InsuranceName=${insuranceName}&SearchQuery=${searchQuery}`

  if (doctorId) {
    url += `&doctorId=${doctorId}`
  }
  const response = await axios.get<Patient[]>(API_URL + url)
  return response || null
}
export const getPatientsList = async (
  pageNumber = 1,
  pageSize = 10,
  searchQuery = ''
): Promise<AxiosResponse<PatientList[]>> => {
  let doctorId: string | null = null
  if (getUserRole() == Role.Staff) {
    doctorId = localStorage.getItem('selectedDoctor')
  }
  let url = `/GetPatientList?pageNumber=${pageNumber}&pageSize=${pageSize}&SearchQuery=${searchQuery}`

  if (doctorId) {
    url += `&doctorId=${doctorId}`
  }
  const response = await axios.get<PatientList[]>(API_URL + url)
  return response || null
}
export const getPatientByRecord = async (
  recordID: string
): Promise<PatientInfo[]> => {
  const { data } = await axios.get<PatientInfo[]>(
    API_URL + `/GetByRecord?RecordID=${recordID}`
  )
  return data
}
export const getRecordDiagnostics = async (
  pageNumber = 1,
  pageSize = 10,
  recordID: string
): Promise<AxiosResponse<RecordDiagnostic[]>> => {
  const response = await axios.get<RecordDiagnostic[]>(
    API_URL +
      `/GetRecordDiagnostics?pageNumber=${pageNumber}&pageSize=${pageSize}&RecordID=${recordID}`
  )
  return response || null
}
export const getResolve = async (): Promise<PatientResolve> => {
  const { data } = await axios.get<PatientResolve>(API_URL + `/PatientsResolve`)
  return data
}
export const getCalculatorResolve = async (): Promise<CalculatorResolve> => {
  const { data } = await axios.get<CalculatorResolve>(
    API_URL + `/CalculatorResolve`
  )
  return data
}
export const updateBillingSemester = async (
  model: UpdateBillingSemester
): Promise<UpdateBillingSemester[]> => {
  const { data } = await axios.put<UpdateBillingSemester[]>(
    API_URL + `/UpdateBillingSemester`,
    model
  )
  return data
}
export const updateBillingSemesterList = async (
  model: UpdateBillingSemester
): Promise<UpdateBillingSemester[]> => {
  const { data } = await axios.put<UpdateBillingSemester[]>(
    API_URL + `/UpdateBillingSemesterList`,
    model
  )
  return data
}
export const updatePatientStatus = async (
  model: UpdatePatientStatus
): Promise<UpdatePatientStatus[]> => {
  const { data } = await axios.put<UpdatePatientStatus[]>(
    API_URL + `/UpdatePatientStatus`,
    model
  )
  return data
}
export const updatePatient = async (model: PatientVM): Promise<PatientVM> => {
  const { data } = await axios.put<PatientVM>(API_URL + `/UpdatePatient`, model)
  return data
}
export const postPatient = async (
  model: PatientVM
): Promise<PatientPostResult> => {
  const { data } = await axios.post(API_URL, model)
  return data
}
export const uploadFile = async (
  file: FormData
): Promise<PatientPostResult> => {
  const { data } = await axios.post(API_URL + `/Upload`, file)
  return data
}


import { Component, Vue, Watch } from 'vue-property-decorator'
import Multiselect from 'vue-multiselect'

import {
  getResolve,
  updateDoctor,
  postDoctor
} from '@/components/doctors/doctors.service'
import {
  PaginationVM,
  SelectVM,
  UserVM,
  RMTCreatedUser,
  Insurance,
  Role
} from '@/models'
import moment from 'moment'
import {
  deleteUser,
  getUsers,
  resendPassword,
  _change2FA,
  _toggleMaintenance,
  getUserRole,
  _validatePassword
} from '@/components/admin/users/users.service'
import { getInsurances } from '@/components/insurances/insurance.service'

@Component({
  components: {
    Multiselect
  }
})
export default class UsersView extends Vue {
  public expandAllRows = false
  public formExpanded = false
  public showInsurances = false
  public confirmation = ''
  public fields = [
    {
      key: 'expand',
      label: ''
    },
    'index',
    // {
    //   key: 'userId',
    //   label: 'User ID'
    // },
    {
      key: 'documentID',
      label: 'Document ID',
      sortable: true
    },
    {
      key: 'speciality',
      label: 'Speciality',
      sortable: true
    },
    {
      key: 'fullName',
      label: 'Username',
      sortable: true
    },
    {
      key: 'sex',
      label: 'Sex',
      sortable: true
    },
    {
      key: 'dob',
      label: 'DOB',
      formatter: 'formatDate',
      sortable: true
    },
    {
      key: 'phone',
      label: 'Phone 1',
      sortable: true
    },
    // {
    //   key: 'mobile',
    //   label: 'Phone 2'
    // },
    {
      key: 'email1',
      label: 'Email',
      sortable: true
    },
    // {
    //   key: 'street',
    //   label: 'Street'
    // },
    // {
    //   key: 'city',
    //   label: 'City'
    // },
    // {
    //   key: 'state',
    //   label: 'State'
    // },
    // {
    //   key: 'zip',
    //   label: 'Zip'
    // },

    // {
    //   key: 'status',
    //   label: 'Status'
    // },
    {
      key: 'rolName',
      label: 'Role',
      sortable: true
    },
    // {
    //   key: 'insuranceName',
    //   label: 'Insurance',
    //   sortable: true
    // },

    {
      key: 'active',
      label: 'Active',
      sortable: true
    },
    {
      key: 'twoFactorEnabled',
      label: '2FA',
      sortable: false
    },
    {
      key: 'show_details',
      label: ''
    }
  ]
  public items: RMTCreatedUser[] = []
  public documentLabel = 'NPI'
  public insurances: Insurance[] = []
  public insurancesPlans: SelectVM[] = []
  public selectedDoctor: SelectVM | null | string = null
  public doctorList: SelectVM[] = []
  public genders: SelectVM[] = []
  public roles: SelectVM[] = []

  public selectedGender: string | null = null
  public selectedRole: string | null = null
  public isAdmin = false
  public form = {} as UserVM
  public isEditing = false
  public perPage = 10
  public pageOptions = [10, 20, 50, 100, 250, 500, 1000]
  public pagination = {} as PaginationVM
  public searchQuery = ''
  public currentRole = ''
  public formatted = ''
  public selectedInsurance = ''

  @Watch('perPage')
  perPageChange() {
    this.getUsers()
  }
  public async onPageChange(pageNumber: number) {
    this.expandAllRows = false
    await this.getUsers(pageNumber, this.searchQuery)
  }

  get _pagination() {
    return this.pagination
  }
  private async created() {
    if (getUserRole() == Role.Admin) {
      this.isAdmin = true
    }
    await this.getUsers()
  }
  public onContext(ctx) {
    this.formatted = moment(ctx).format('MM/DD/YYYY')
  }
  public onInput(ctx) {
    const a = moment(ctx).toDate()
    if (a.toString() != 'Invalid Date') this.form.dob = a
  }
  public async getUsers(pageNumber = 1, searchQuery = '') {
    const response = await getUsers(pageNumber, this.perPage, searchQuery)
    this.items = response.data
    this.pagination = JSON.parse(response.headers['x-pagination'])
  }
  @Watch('selectedRole')
  async onSelectedRoleChange(value: string) {
    const a = this.roles.find(z => z.value == value)?.label
    this.showInsurances = false
    if (a === Role.Doctor) {
      this.documentLabel = 'NPI'
      this.form.role = Role.Doctor
    } else if (a === Role.Staff || a === Role.Training) {
      this.documentLabel = 'DocumentID'
      this.form.role = a
    } else if (a === 'InsuranceStaff') {
      this.documentLabel = 'DocumentID'
      this.form.role = 'InsuranceStaff'
      const response = await getInsurances(1, 1500)
      this.insurances = response.data

      this.showInsurances = true
      // console.log(this.selectedInsurance)
      // console.log(response.data)
    }
  }
  public async filter() {
    await this.getUsers(1, this.searchQuery)
  }
  public async cleanFilter() {
    this.searchQuery = ''
    await this.getUsers(1, this.searchQuery)
  }
  public async toggleForm() {
    this.formExpanded = !this.formExpanded
    if (!this.formExpanded) {
      this.selectedRole = null
      this.form = {} as UserVM
      this.isEditing = false
      this.confirmation = ''
    } else {
      await this.fillFields()
    }
  }
  public toggleUnderMaintenance() {
    this.$swal
      .fire({
        title: 'Are you sure?',
        text: 'This will set the website as UNDER MAINTENANCE',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm'
      })
      .then((result): void => {
        if (result.isConfirmed) {
          this.$swal
            .fire({
              title: 'Please confirm your password',
              html: `
  <input type="password" autocomplete="new-password" id="password" class="swal2-input" placeholder="Password">`,
              confirmButtonText: 'Confirm',
              focusConfirm: false,
              preConfirm: () => {
                const password = this.$swal
                  .getPopup()
                  .querySelector('#password').value
                if (!password) {
                  this.$swal.showValidationMessage(`Please enter your password`)
                }
                return { password: password }
              }
            })
            .then(result => {
              _validatePassword(result.value.password).then(c => {
                if (c === true) {
                  _toggleMaintenance(true).then(() => {
                    this.$swal.fire({
                      text: 'This website now is UNDER MAINTENANCE',
                      icon: 'success'
                    })
                  })
                } else {
                  this.$swal.fire({
                    text: 'Wrong password',
                    icon: 'warning'
                  })
                }
              })
            })
        }
      })
  }
  public toggleRow() {
    this.items = this.items.map(c => {
      return { ...c, _showDetails: !c._showDetails }
    })
    this.expandAllRows = !this.expandAllRows
  }
  // private async _getDoctors(pageNumber = 1, searchQuery = '') {
  //   const response = await getDoctors(pageNumber, this.perPage, searchQuery)
  //   this.items = response.data
  //   this.pagination = JSON.parse(response.headers['x-pagination'])
  // }
  public formatDate(value: string) {
    if (value) return moment(value).format('MM/DD/YYYY')
  }
  public getAge(value: string) {
    if (value) return moment().diff(value, 'years')
  }
  public async fillFields() {
    if (!this.formExpanded) return
    const response = await getResolve()
    if (!response) return

    this.genders = response.genders
    this.roles = response.roles
  }
  public async edit(item: UserVM) {
    this.formExpanded = true
    await this.fillFields()
    this.isEditing = true
    this.form = {
      ...item,
      phone: item.phone?.trim(),
      mobile: item.mobile?.trim()
    }
    this.selectedInsurance = item.insuranceID?.toString()
    this.confirmation = item.email1

    this.selectedGender = this.genders.find(
      z => z.label === this.form.sex
    )?.value
    this.selectedRole = item.roleId

    this.formatted = this.formatDate(this.form.dob.toString())
  }

  public async resendPassword(item: UserVM) {
    this.$swal
      .fire({
        title: 'Are you sure?',
        text: `This will reset the user password and send it to his/her inbox`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm'
      })
      .then(result => {
        if (result.isConfirmed) {
          resendPassword(item)
        }
      })
  }
  public async create(): Promise<void> {
    const isValid = await this.$refs.observer.validate()
    if (!isValid) {
      return
    }

    const obj = {
      ...this.form,
      insuranceID: +this.selectedInsurance || null,
      sex: this.genders.find(c => c.value == this.selectedGender)?.label,
      role: this.form.rolName
    } as UserVM

    if (!this.isEditing) {
      obj.role = this.form.role
      await postDoctor(obj)
    } else {
      // console.log(obj)
      await updateDoctor(obj)
    }
    this.formExpanded = false
    // this.isEditing = false
    // this.formExpanded = false
    this.form = {} as UserVM
    await this.getUsers()
  }
  public change2FA(checked: boolean, item: RMTCreatedUser) {
    _change2FA({ checked, userId: item.userId })
  }
  public changeStatus(checked: boolean, item: RMTCreatedUser) {
    this.$swal
      .fire({
        title: 'Are you sure?',
        text: `This user status will be set as ${
          checked ? 'Active' : 'Inactive'
        }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm'
      })
      .then((result): void => {
        if (result.isConfirmed) {
          deleteUser(item.userId)
          this.$swal.fire(
            `${checked ? 'Activated' : 'Deactivated'}!`,
            `The user has been ${
              checked ? 'Activated' : 'Deactivated'
            } successfully`,
            'success'
          )
        } else {
          item.active = !item.active
        }
      })
  }
}

export interface Insurance {
  insuranceID: number
  insuranceName: string
  insuranceStatus: boolean
  recordDateTime: Date
  rmtInsuranceAgreements: RmtInsuranceAgreement[]
  rmtInsurancePlans: RmtInsurancePlan[]
}
export interface PaginationVM {
  totalCount: number
  pageSize: number
  currentPage: number
  totalPages: number
  previousPageLink: null
  nextPageLink: string
}

export interface SpResult {
  result: number
  error: string
}
export interface SelectVM {
  value: string
  label: string
}

export interface CurrentUser {
  fullName: string
  userId: number
  userName: string
  rmtUserId: number
}
export interface InsurancePostResult {
  insuranceID: number
  insuranceName: string
  error: string
}
export interface PatientPostResult {
  documentID: string
  firstName: string
  error: string
}
export interface RmtInsuranceAgreement {
  insuranceId: number
  doctorId: number
  valueOfPremium: number
  expenses: number
  surplusAgreement: number
  agreementDate: Date
  modifiedDate: Date
  doctor: Doctor
  ipaNumber: number
}

export interface Doctor {
  doctorId: number
  documentId: string
  speciality: string
  firstName: string
  lastName: string
  fullName: string
  sex: string
  dob: Date
  phone: string
  mobile: string
  email1: string
  email2: string
  street: string
  city: string
  state: string
  zip: string
  doctorStatus: boolean
  rmtDoctorsAndPatients: RmtDoctorsAndPatient[]
  rmtInsuranceAgreements: null[]
  rmtRecords: RmtRecord[]
  _showDetails: boolean
}
export interface FormsResolve {
  forms: FormsVM[]
  doctors: DoctorSelectVM[]
  formType: SelectVM[]
}
export interface FormVM {
  doctorID: number
  formsID: number
  formsTypeID: number
  url: string | null
}

export interface FormsVM extends SelectVM {
  formsCategoryName: string
}
export interface GroupFormsVM {
  formCategoryName: string
  libs: SelectVM[]
}
export interface DoctorSelectVM extends SelectVM {
  documentId: string
}
export interface PostFormResult {
  doctorID: number
  formsName: string
  url: string
}
export interface MagForm {
  doctorId: number
  formsTypeID: number
  formsID: number
  fullName: string
  formsCategoryName: string
  formsName: string
  formsTypeName: string
  url: string
  npi: string
}
export interface MagHomeForm {
  doctorId: number
  formsCategoryName: string
  formsName: string
  url_Forms: string
  url_Record: string
}
export interface InsuranceAgreementsResolve {
  insurances: SelectVM[]
  doctors: SelectVM[]
}
export interface RmtDoctorsAndPatient {
  patientId: number
  doctorId: number
}

export interface RmtRecord {
  recordId: string
  patientId: number
  doctorId: number
  recordDate: Date
  recordDateTime: Date
  rmtNotes: RmtNote[]
  rmtRecordsDetails: RmtRecordsDetail[]
}

export interface RmtNote {
  noteId: number
  recordId: string
  doctorNotes: string
  magnotes: string
}

export interface RmtRecordsDetail {
  recordId: string
  diagnosticId: string
  descriptions: string
  cmshcc1: number
  cmshcc2: number
  cmshcc3: number
  rxHcc1: number
  rxHcc2: number
  idinteraction: number
  alert: string
  alertDelete: string
  documentation: string
  crosswalkDx: string
  crosswalkRx: string
  diagnosticDate: Date
  diagnosticStatus: boolean
  billingFsm: boolean
  billingSsm: boolean
  modifiedDate: Date
  diagnostic: Diagnostic
}

export interface Diagnostic {
  diagnosticId: string
  descriptions: string
  cmshcc1: number
  cmshcc2: number
  cmshcc3: number
  rxHcc1: number
  rxHcc2: number
  idinteraction: number
  alert: string
  alertDelete: string
  serviceDate: Date
  billingFSM: boolean
  billingSSM: boolean
  documentation: string
  crosswalkDx: string
  crosswalkRx: string
  diagnosticStatus: boolean
  rxHcc1Navigation: RxHccNavigation
  rxHcc2Navigation: RxHccNavigation
  rmtRecordsDetails: null[]
}

export interface RxHccNavigation {
  rxHccId: number
  rxHccRafValue: number
  rmtDiagnosticRxHcc1Navigations: null[]
  rmtDiagnosticRxHcc2Navigations: null[]
}

export interface RmtInsurancePlan {
  insurancePlanId: number
  insuranceId: number
  planId: number
  plan: Plan
  rmtPatients: RmtPatient[]
}

export interface Plan {
  planId: number
  plan: string
  rafValue: number
  rmtInsurancePlans: null[]
}

export interface Patient {
  doctorId: number
  documentID: string
  fullName: string
  insuranceName: string
  plan: string
  sex: string
  DOB: Date
  patientStatus: boolean
  recordID: string
  DrRecordID: string
  insuranceContractNo: string
  recordDate: Date
  RAF_TOTAL: number
}
export interface PatientInfo {
  documentID: string
  fullName: string
  insuranceName: string
  plan: string
  sex: string
  dob: Date
  patientStatus: string
  recordID: string
  recordDate: Date
  doctorNotes: string
  magNotes: string
  raF_TOTAL: string
  raF_TOTAL_BilledFSM: string
  raF_TOTAL_BilledSSM: string
  percentage_BFSM: number
  percentage_BSSM: number
}
export interface RecordDiagnostic {
  diagnosticId: string
  descriptions: string
  cmsHcc1: number
  rxHcc1: number
  crosswalkDx: string
  crosswalkRx: string
  idInteraction: number
  documentation: string
  alert: string
  delete: string
  billingFSM: boolean
  billingSSM: boolean
  diagnosticDate: Date
  modifiedDate: Date
  diagnosticStatus: boolean
  _showDetails: boolean
  queueFlag: boolean
}
export interface PostRecordDiagnostic {
  diagnostics: string
  recordID: string
  optimizerFlag: boolean
}
export interface PostRecordDiagnosticTemp {
  diagnostics: string
  recordID: string
  optimizerFlag: boolean
  dob: string
  patientName: string
  plan: string
  sex: string
}
export interface RecordDiagnosticTempResult {
  recordID: string
  age: string
  sex: string
  plan: string
  dob: Date

  patientName: string
  raF_TOTAL: number
  diagnosticID: string
  descriptions: string
  cmshcc1: number | string
  cmshcc2: number | string
  rxHcc1: number
  rxHcc2: number
  idinteraction: number
  crosswalkDx: string
  crosswalkRx: string
}
export interface PostAutoBilling {
  diagnosticID: string
  recordID: string
  billing: boolean
  billingSemester: number
}
export interface CalculatorForm {
  recordID: string
  dob: Date
  patientName: string
  plan: SelectVM
  sex: SelectVM
}
export interface RecordDiagnosticResult {
  diagnosticID: string
  Error: string
}
export interface UpdateBillingSemester {
  recordID: string
  diagnosticID: string
  billing: boolean
  billingSemester: number
}

export interface UpdateDiagnosticStatus {
  recordID: string
  diagnosticID: string
  statusValue: boolean
}
export interface UpdateDiagnostic {
  descriptions: string
  cmshcc1: number | string
  cmshcc2: number | string
  cmshcc3: number | string
  rxHcc1: number
  rxHcc2: number
  idinteraction: number
  alert: string
  alertDelete: string
  documentation: string
  crosswalkDx: string
  crosswalkRx: string
  diagnosticStatus: boolean
}
export interface UpdatePatientStatus {
  doctorId: number
  documentID: string
  statusValue: boolean
}
export interface UpdateEntityStatus {
  documentID: string
  statusValue: boolean
}
export interface UpdateInsuranceStatus {
  insuranceID: number
  statusValue: boolean
}
export interface UpdateInsurance {
  insuranceName: string
  insuranceID: number
}
export interface UpdateInsuranceAgreement {
  insuranceName: string
  doctorDocumentID: string
  valueOfPremium: number
  expenses: number
  surplusAgreement: number
}
export interface InsuranceAgreementsVM {
  insuranceID: number
  insuranceName: string
  ipaNumber: number
  doctorId: number
  doctorName: string

  doctorDocumentID: string
  valueOfPremium: number
  expenses: number
  surplusAgreement: number
  agreementDate: Date
  modifiedDate: Date
  doctor: Doctor
}

export interface PatientVM {
  recordID: string
  doctorId: number
  planID: number
  insuranceID: string
  drRecordID: string
  documentID: string
  firstName: string
  lastName: string
  sex: string
  sexId: number
  dob: Date
  insuranceName: string
  insurancePlan: string
  phone: string
  mobile: string
  email1: string
  email2: string | null
  street: string
  city: string
  state: string
  zip: string
  insuranceContractNo: string
}
export interface UserVM {
  roleId: number
  role: string
  documentID: string
  speciality: string
  firstName: string
  lastName: string
  sex: string
  sexId: number
  dob: Date
  phone: string
  mobile: string
  email1: string
  email2: string
  street: string
  city: string
  state: string
  zip: string
  userId: number
  insuranceID: number | null
  ipaNumber: number | null
}
export interface TwoFactorUser {
  checked: boolean
  userId: number
}
export interface RMTCreatedUser {
  userId: number
  rMT_UserId: number
  documentID: string
  speciality: string
  fullName: string
  sex: string
  dob: Date
  phone: string
  mobile: string
  email1: string
  street: string
  city: string
  state: string
  zip: string
  status: boolean
  rolName: string
  active: boolean
  _showDetails: boolean
  twoFactorEnabled: boolean
}
export interface UserIdentity {
  userId: number
  rmT_UserId: number
  rolName: string
  documentId: string
  speciality: string
  fullName: string

  sex: string

  dob: Date
  phone: string
  mobile: string
  email1: string

  street: string
  city: string
  state: string
  zip: string
  status: boolean
  active: boolean
}
export interface DoctorPostResult {
  documentID: string
  firstName: string
  error: string
}
export interface DoctorNotePostResult {
  recordId: string
  doctorNotes: string
  error: string
}
export interface MAGNotePostResult {
  recordId: string
  MAGNotes: string
  error: string
}
export interface RmtPatient {
  patientId: number
  documentId: string
  firstName: string
  lastName: string
  fullName: string
  sexId: number
  dob: Date
  phone: string
  mobile: string
  email1: string
  email2: string
  insurancePlanId: number
  street: string
  city: string
  state: string
  zip: string
  patientStatus: boolean
  sex: Sex
  rmtDoctorsAndPatients: RmtDoctorsAndPatient[]
  rmtRecords: RmtRecord[]
}

export interface Sex {
  sexId: number
  sex: string
  rafValue: number
  rmtPatients: null[]
}

export interface PatientResolve {
  insurances: SelectVM[]
  insurancePlans: SelectVM[]
  genders: SelectVM[]
  doctors: SelectVM[]
}
export interface CalculatorResolve {
  insurancePlans: SelectVM[]
  genders: SelectVM[]
}
export interface DoctorResolve {
  genders: SelectVM[]
  roles: SelectVM[]
}
export interface PatientList {
  doctorId: number
  documentID: string

  doctor: string
  speciality: string
  patient: string
  insuranceName: string
  patientStatus: true
  dobMmDdYyyy: string
  age: number
  sex: string
  phone: string
  mobile: string
  email1: string
  email2: string
  street: string
  city: string
  state: string
  zip: string
  _showDetails: boolean
}

export interface LoginVM {
  userName: string
  password: string
  code: string
  trustBrowser: boolean
}
export interface LoginResultSessionVM {
  userName: string
  email: string
  token: string
  tokenKey: string
  role: string
  needsADoctor: boolean
  is2FA: boolean
  hint: string
  userId: number
  // public string Email { get; set; }
  // public string Token { get; set; }
  // public string TokenKey { get; set; }
  // public string Role { get; set; }
  // // public List<SelectVM> Companies { get; set; }
  // public ICollection<Menu> Menus { get; set; }
}
export interface ExternalResponse {
  token: string
  account: string
}
export interface Menu {
  id: number
  icon: string
  name: string
  path: string
  parents: string | null
  menus: Menu[]
  options: string[]
  position: number
}

export interface GalleryPostVM {
  file: File
  formData: FormData
  title: string
  description: string
  // preview: File
  roles: SelectVM[]
  rolId: string
  date: Date
  categoria: string
  previewImagePath: string
  status: boolean
  preview: string
  videoPath: string
}

export interface VideoResolve {
  video: Videos
  videos: Videos[]
  file: File
}

export interface Videos {
  videoID: number
  title: string
  description: string
  preview: string
  roles: SelectVM[]
  categoria: string
  creationDate: Date
  status: boolean
  rolesChain: string
}
export interface VideosVM {
  videoID: number
  description: string
  previewImagePath: string
  rolesChain: string
  title: string
  preview: string
  creationDate: Date
  videoPath: string
  status: boolean
  categoria: string

  // rolId: number[]
}
export interface UpdateVideos {
  videoID: number
  title: string
  description: string
  categoria: string
  previewImagePath: string
  rolId: string
  status: boolean
  preview: string
  // createdDate: Date
}

export interface VwLoginReport {
  Username: string
  FirstName: string
  LastName: string
  LastLogin: Date
  DAYS_ACTIVITY: string
  // createdDate: Date
}
export interface NotificationMessage {
  UserId: string
  MessageTitle: string
  MessageBody: string
  ByRole: boolean
}
export interface Notification {
  NotificationID: number
  RolId: number
  UserId: number
  UserId_M: number
  CreateDate: Date
  ReadFlag: boolean
  ReadDate: Date
  MessageTitle: string
  MessageBody: string
  EditUserID: number
}

export interface PostNotification {
  Roles: string
  UserId_M: string
  MessageTitle: string
  MessageBody: string
}
export interface NotificationResult {
  NotificationID: number
  Error: string
}

export enum Role {
  Superuser = 'Superuser',
  Admin = 'Admin',
  Staff = 'Staff',
  InsuranceStaff = 'InsuranceStaff',
  Doctor = 'Doctor',
  Training = 'Training'
}
